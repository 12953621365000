@use '../../../styles/mixins' as m;

.phone-input {
  height: 48px !important;
}

.react-tel-input .form-control {
  width: 100%;
}

.react-tel-input .country-list {
  position: fixed;

  @include m.point(lg-low) {
    position: absolute;
  }
}

.react-tel-input .flag-dropdown.open .selected-flag,
.react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus {
  background-color: transparent;
}

.react-datepicker-wrapper {
  width: 100%;
}

.tooltip {
  position: absolute;
  top: 0;
  right: 0;
  width: 18px;
  height: 18px;
}

.field-icon {
  position: absolute;
  right: 10px;
  top: calc(50% - 8px);
}

.phoneInput {
  width: 100% !important;
  height: 43px !important;

  &:focus {
    box-shadow: none;
  }

  .phoneDropdown {
    width: 100%;
  }
}

.light {
  .react-tel-input .country-list .country {
    &.highlight {
      background-color: rgba(var(--main-color), .2) !important;
    }

    &:hover {
      background-color: rgba(var(--main-color), .1);
    }
  }
}

.dark {
  .react-tel-input .country-list .country {
    &.highlight {
      background-color: rgb(var(--background-color)) !important;
    }

    &:hover {
      background-color: rgb(var(--background-color));
    }
  }
}