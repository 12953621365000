.select-input {
  .select-input {
    &__control {
      height: 48px;
      box-shadow: none;

      &--is-focused,
      &--is-focused:hover {
        border-color: rgb(var(--main-color));
      }

      &--menu-is-open {
        .select-input__indicator svg {
          transform: rotate(180deg);
        }
      }
    }

    &__single-value {
      color: rgb(var(--text-color));
    }

    &__indicator {
      padding: 8px 12px;
      color: rgb(var(--main-color));

      &-separator {
        display: none;
      }
    }

    &__menu {
      z-index: 11;
      overflow: hidden;
      margin: 2px 0;
      border-radius: 8px;
      border: 1px solid;
      border-top: none;

      & > div {
        padding: 0;
      }
    }

    &__option {
      padding: 10px 12px;
      font-size: 13px;
      line-height: 20px;
      background-color: rgb(var(--section-color));
      color: rgb(var(--text-color));

      &--is-selected {
        background-color: rgba(var(--main-color), .2) !important;
      }

      &--is-focused,
      &:active {
        background-color: rgba(var(--main-color), .1);
      }
    }
  }
}

.is-loading {
  cursor: not-allowed;

  .select-input__control {
    background-color: var(--light-grey-disabled);
    pointer-events: none;
  }
}

//Theme styles

.light {
  .select-input {
    .select-input__control {
      &--is-disabled {
        background-color: #efefef;
        .select-input__single-value {
          color: #989898;
        }
      }
    }

    &__menu {
      border-color: #c2c2c2;
    }
  }
}

.dark {
  .select-input {
    .select-input__control {
      &--is-disabled {
        background-color: #9b9ba2;
      }
    }

    &__menu {
      border-color: rgb(var(--main-color));
      background-color: rgb(var(--section-color));
    }
  }
}