@use '../../styles/mixins' as m;

.page-nav-link {
  margin-left: 10px;
  padding: 12px 16px;
  font-size: 14px;
  text-transform: uppercase;
  text-decoration: none;
  color: rgb(var(--text-color));

  &:first-child {
    margin-left: 0;
  }

  &:hover {
    color: rgb(var(--main-color));
  }

  &.active {
    color: rgb(var(--main-color));
  }
}

@include m.point(lg-low) {
  .page-nav {
    flex-direction: column;
  }

  .page-nav-list,
  .page-nav-actions {
    width: 100%;
  }

  .page-nav-list {
    margin-bottom: 15px;
  }

  .page-nav-link {
    margin-left: 3px;
    padding: 12px 10px;
    font-size: 12px;
  }
}

//Theme styles
.theme-1 {
  .page-nav-link {
    border-bottom: 2px solid transparent;

    &.active {
      border-bottom: 2px solid rgb(var(--main-color));
    }
  }
}

.theme-2 {
  .page-nav-link {
    border-radius: 8px;
  }
}

.theme-2--light,
.theme-3--light {
  .page-nav-link {
    &.active {
      box-shadow: 0 0 20px 0 rgba(var(--main-color), .15);
    }
  }
}

.theme-2--dark,
.theme-3--dark {
  .page-nav-link {
    &.active {
      background-color: rgb(var(--secondary-color));
      box-shadow: 0 0 20px 0 rgba(#000, .25);
    }
  }
}

.theme-3 {
  .page-nav-link {
    border-radius: 20px;
  }
}
