@use '../../styles/mixins' as m;

.documents-page {
  .list {
    padding-left: 25px;
  }

  p, li {
    margin: 5px 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
  }

  .fi-rr-cloud-upload {
    font-size: 80px;
  }

  .file,
  .submit-files {
    display: none;
  }
}

@include m.point(lg-up) {
  .documents-page {
    .documents-page-text {
      height: calc(100vh - 190px);
      overflow-y: auto;
      overflow-x: hidden;
    }

    .file-uploader {
      height: 100%;

      .fi-rr-cloud-upload {
        margin-top: 5vh;
        margin-bottom: 5vh;
      }

      .file-uploader-title-divider,
      .file-uploader-title {
        margin-bottom: 4vh;
      }
    }
  }
}

@include m.point(lg-low) {
  .documents-page {
    .documents-page-text {
      margin-bottom: 20px;
    }
  }
}

