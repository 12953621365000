@use '../../../styles/mixins' as m;

.private-layout-header {
  top: 0;
  left: 0;
  padding: 12px 15px;

  .profile-avatar,
  .btn {
    margin-left: 80px;
  }

  .client-name {
    max-width: 200px;
    line-height: 1.5;
  }
}

.header-logo {
  display: none;
  width: 66px;
  height: 37px;
  font-size: 0;

  img {
    left: 0;
    transform: translate(0, -50%);
    max-height: 100%;
  }
}


@include m.point(md-xl) {
  .private-layout-header .profile-avatar,
  .private-layout-header .btn {
    margin-left: 40px;
  }
}

@include m.point(lg-low) {
  .private-layout-header {
    z-index: 10;

    .header-logo {
      display: none !important;
    }

    .profile-avatar,
    .btn {
      margin-left: 30px;
    }
  }

  .client-name {
    display: none;
  }
}

@include m.point(sm-low) {
  .private-layout-header {
    .profile-avatar,
    .btn {
      margin-left: 15px;
    }
  }
}

@include m.point(md-low) {
  .private-layout-header {
    padding: 12px 8px 12px 12px;
  }
}

//Theme styles
.theme-1, .theme-3 {
  .private-layout-header {
    background-color: rgb(var(--section-color));
  }
}

.theme-1 {
  .header-logo {
    display: block;
  }
}

.theme-1--dark, .theme-3--dark {
  .private-layout-header {
    box-shadow: 0 0 20px 0 rgba(#000, .25);
  }
}

.theme-1--light, .theme-3--light {
  .private-layout-header {
    box-shadow: 0 0 20px 0 rgba(var(--main-color), .15);
  }
}
