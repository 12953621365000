@use '../../../styles/mixins' as m;

.payment-form-page {
  height: calc(100vh - 124px);

  .form-overflow {
    overflow-y: auto;
    overflow-x: hidden;
  }

  .input-field-label {
    padding-left: 16px;
    padding-right: 16px;
  }

  h1 {
    margin-bottom: 0;
  }
}

@include m.point(md-low) {
  .payment-form-page {
    h1 {
      font-size: 22px;
    }
  }
}