@use './src/styles/mixins' as m;

.auth-layout {
  --text-color: 38, 38, 38;
  background-color: #fff;
  color: rgb(var(--text-color));

  &.inverse {
    .auth-layout-title {
      order: 1;
    }
  }

  &.sign-up {
    .auth-layout-title {
      font-size: 48px;
    }
  }

  .auth-layout-title {
    position: relative;
    font-size: 64px;
    line-height: 1.4;
    text-shadow: 1px 1px 1px #969696;
    color: rgb(var(--main-color));

    &::after {
      content: "";
      opacity: .3;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: #fff;
    }

    span {
      z-index: 1;
      position: relative;
      padding: 0 20%;
    }
  }

  .auth-layout-overflow-wrap {
    max-height: 100%;
    padding: 40px 0;

    @include m.point(lg-up) {
      overflow-y: auto;
    }
  }

  .auth-layout-form {
    width: 440px;
    max-width: 100%;

    @media (max-width: 360px) {
      width: 320px;
    }

    @media (min-width: 361px) and (max-width: 400px) {
      width: 360px;
    }

    @media (min-width: 401px) and (max-width: 440px) {
      width: 400px;
    }
  }

  .lang-switcher {
    .dropdown {
      box-shadow: 0 0 20px 0 rgba(var(--main-color), .15);
      background-color: #fff;
    }

    .lang-switcher-option {
      color: rgb(38, 38, 38);
    }
  }

  .form-header-title,
  .input-field-label,
  .lang-switcher .fi-rr-caret-down{
    color: rgb(var(--accent-color));
  }

  .input-field {
    .input,
    .textarea,
    .select-input__control {
      background-color: #fff;
    }
  }

  .select-input .select-input__option {
    background-color: #fff;

    &--is-selected {
      background-color: rgba(var(--main-color), .2) !important;
    }

    &--is-focused {
      background-color: rgba(var(--main-color), .1);
    }
  }

  @include m.point(lg-low) {
    .auth-layout-title {
      display: none;
    }

    .auth-layout-overflow-wrap {
      max-height: none;
    }

    .auth-layout-form-wrap {
      height: auto;
      min-height: 100vh;
      width: 100%;
    }
  }
}