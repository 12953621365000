@use "../../../styles/mixins" as m;

.private-layout-menu {
  z-index: 3;
  position: fixed;
  top: 0;
  left: 0;

  a {
    display: flex;
    margin-bottom: 8px;
    text-decoration: none;
    color: inherit;

    &:hover {
      color: inherit;
    }

    .layout-menu-option {
      margin-bottom: 0;
    }
  }
}

.private-layout-menu-logo {
  display: none;
  max-width: 40px;
  height: 33px;
  margin: 8px 4px 0;
  font-size: 0;

  & > img {
    max-height: 100%;
  }
}

.private-layout-menu-list {
  padding: 15px 4px;
}

.layout-menu-option {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  border-radius: 8px;
  cursor: pointer;

  &:hover .layout-menu-option-title {
    opacity: 1;
    transition: opacity .1s ease-out;

    .option-text {
      max-width: 250px;
    }
  }
}

.layout-menu-option-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 40px;
  height: 40px;

  & > i {
    height: 24px;
  }

  .badge {
    z-index: 1;
    position: absolute;
    top: 3px;
    left: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: rgb(var(--main-color));
    font-size: 10px;
    color: #fff;
  }
}

.layout-menu-option-title {
  opacity: 0;
  position: absolute;
  top: 0;
  left: calc(100% - 8px);

  .option-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 0;
    padding: 12px 12px 12px 8px;
    border-radius: 0 8px 8px 0;
    font-size: 16px;
    font-weight: 600;
    transition: max-width .4s ease-out;
  }
}

@include m.point(lg-low) {
  .private-layout-menu {
    width: 100%;
    transform: translateX(-100%);
    transition: transform .2s ease;

    &.opened {
      transform: translateX(0);

      .layout-menu-option-title,
      .private-layout-menu-list {
        width: 100%;
      }
    }

    .menu-bottom {
      margin-top: 0;
    }
  }

  .layout-menu-option {
    width: 100%;
  }

  .layout-menu-option-title {
    opacity: 1;
    position: static;

    .option-text {
      max-width: none !important;
    }
  }
}

//Theme styles
.theme-1,
.theme-3 {
  @include m.point(lg-low) {
    .layout-menu-option.active {

      .option-text {
        color: rgb(var(--text-color));
      }
    }
  }
}

.theme-1--light,
.theme-3--light {
  .layout-menu-option {
    .layout-menu-option-icon {
      color: rgb(var(--accent-color));
    }

    @include m.point(lg-up) {
      &:hover {
        color: rgb(var(--accent-color));

        .layout-menu-option-icon {
          background-color: rgb(var(--secondary-color));
          color: rgb(var(--main-color));
        }

        .option-text {
          background-color: rgb(var(--secondary-color));
        }
      }
    }

    &.active {
      .layout-menu-option-icon {
        background-color: rgb(var(--secondary-color));
        color: rgb(var(--main-color));
      }

      .option-text {
        background-color: rgb(var(--secondary-color));
      }
    }
  }
}

.theme-1--dark,
.theme-3--dark {
  .layout-menu-option {
    &.active {
      .layout-menu-option-icon {
        background-color: rgb(var(--main-color));
        color: rgb(var(--text-color));
      }

      .option-text {
        background-color: rgb(var(--main-color));
      }
    }
  }
}

.theme-1 {
  .private-layout-menu {
    padding-bottom: 30px;
  }

  .layout-menu-option-icon {
    border-radius: 8px;
  }

  .private-layout-menu {
    padding-top: 72px;
    padding-left: 8px;

    @include m.point(lg-low) {
      padding-right: 8px;

      .layout-menu-option.active {
        .layout-menu-option-icon {
          border-radius: 8px 0 0 8px;
        }
      }
    }
  }

  .private-layout-menu-list {
    width: 48px;
    border-radius: 8px;
    background-color: rgb(var(--section-color));
  }
}

.theme-1--dark {
  .layout-menu-option {
    @include m.point(lg-up) {
      &:hover {
        color: rgb(var(--text-color));

        .option-text {
          background-color: rgb(var(--main-color));
        }

        .layout-menu-option-icon {
          background-color: rgb(var(--main-color));
        }
      }
    }
  }
}

.theme-2,
.theme-3 {
  .private-layout-menu-logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.theme-2 {
  .private-layout-menu-logo {
    margin-bottom: 85px;
  }

  .private-layout-menu {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 100%;
      height: 100%;
      width: 25px;
      border-radius: 24px 0 0 24px;
    }
  }

  @include m.point(lg-low) {
    .private-layout-menu {
      padding-top: 15px;
      margin-top: 64px;
      box-shadow: none !important;

      &::before {
        display: none;
      }
    }
    .private-layout-menu-logo {
      margin-bottom: 0;
    }
  }
}

.theme-2--light {
  .private-layout-menu {
    background-color: rgb(var(--accent-color));
    box-shadow: 24px 0 0 0 rgb(var(--accent-color));

    &::before {
      background-color: rgb(var(--section-color));
    }
  }

  .layout-menu-option {
    &.active {
      background-color: rgb(var(--secondary-color));

      .option-text {
        background-color: rgb(var(--secondary-color));
      }
    }
  }

  .layout-menu-option-icon {
    color: rgb(var(--background-color));
  }

  .option-text {
    background-color: rgb(var(--accent-color));
    color: rgb(var(--background-color));
  }
}

.theme-2--dark {
  .private-layout-menu {
    background-color: rgb(var(--background-color));
    box-shadow: 24px 0 0 0 rgb(var(--background-color));

    &::before {
      background-color: rgb(var(--section-color));
    }
  }

  .layout-menu-option {
    &.active {
      background-color: rgb(var(--main-color));

      .option-text {
        background-color: rgb(var(--main-color));
      }
    }
  }

  .layout-menu-option-icon {
    color: rgb(var(--text-color));
  }

  .option-text {
    background-color: rgb(var(--background-color));
    color: rgb(var(--text-color));
  }
}

.theme-3 {
  .private-layout-menu-logo {
    margin-top: 18px;
    margin-bottom: 25px;
  }

  .private-layout-menu {
    padding: 0 4px;
    background-color: rgb(var(--section-color));
    box-shadow: 0 0 20px 0 rgba(var(--main-color), .15);
  }

  .layout-menu-option-icon {
    border-radius: 50%;
  }

  .option-text {
    background-color: rgb(var(--section-color));
    color: rgb(var(--text-color));
  }

  @include m.point(lg-up) {
    .layout-menu-option {
      &:hover {
        .layout-menu-option-icon {
          border-radius: 50% 0 0 50%;
        }
      }
    }
  }

  @include m.point(lg-low) {
    .layout-menu-option.active {
      .layout-menu-option-icon {
        border-radius: 50% 0 0 50%;
      }
    }
  }
}
