@use 'mixins' as m;

$gutter-sizes: 5, 10, 15, 20, 25, 30, 40, 50;

@mixin set-positions($sizes, $property, $prefix, $unit: 'px') {
  $class: unquote("._#{$prefix}");

  @each $value in $sizes {
    @if ($value == auto) {
      $unit: null;
    }

    #{$class}-#{$value} {
      #{$property}: #{$value + $unit};
    }

    #{$class}-x-#{$value} {
      #{$property}-left: #{$value + $unit};
      #{$property}-right: #{$value + $unit};
    }

    #{$class}-y-#{$value} {
      #{$property}-top: #{$value + $unit};
      #{$property}-bottom: #{$value + $unit};
    }

    #{$class}-t-#{$value} {
      #{$property}-top: #{$value + $unit};
    }

    #{$class}-b-#{$value} {
      #{$property}-bottom: #{$value + $unit};
    }

    #{$class}-l-#{$value} {
      #{$property}-left: #{$value + $unit};
    }

    #{$class}-r-#{$value} {
      #{$property}-right: #{$value + $unit};
    }
  }
}

@include set-positions(append($gutter-sizes, auto), 'margin', 'm');
@include set-positions($gutter-sizes, 'padding', 'p');

._pos-a {
  position: absolute;
}

._pos-r {
  position: relative;
}

._pos-f {
  position: fixed;
}

._pos-a-x {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

._pos-a-y {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

._pos-a-c {
  @include m.transform-center();
}

._img-wrap {
  position: relative;
  & > img,
  & > svg {
    max-width: 100%;
    @include m.transform-center();
  }
}

._h-100 {
  height: 100%;
}

._h-100vh {
  height: 100vh;
}

._w-auto {
  width: auto;
}

._w-100 {
  width: 100%;
}

._w-50 {
  width: 50%;
}