$radius-sizes: 0, 5, 10, 15, 20, 25, 30;

@each $value in $radius-sizes {
  ._border-r-#{$value} {
    border-radius: #{$value} + px;
  }
}

@mixin cursor-pointer {
  cursor: pointer;
}

@mixin cursor-default {
  cursor: default;
}

@mixin cursor-grab {
  cursor: grab;
}

.cursor-pointer {
  @include cursor-pointer;
}

.cursor-default {
  @include cursor-default;
}

.cursor-grab {
  @include cursor-grab;
}


._object-fit {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}


._truncate {
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
}

._form-divider {
  margin: 15px 0 25px;
  border: none;
  border-top: 1px solid #c2c2c2;
}