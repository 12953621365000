.light {
  &--blackberry {
    --button-hover-color: 71, 0, 171;
  }

  &--mint {
    --button-hover-color: 17, 171, 144;
  }

  &--ice {
    --button-hover-color: 12, 55, 162;
  }

  &--tangerine {
    --button-hover-color: 186, 124, 6;
  }

  &--raspberry {
    --button-hover-color: 159, 4, 88;
  }

  &--blueberry {
    --button-hover-color: 2, 40, 75;
  }
}

.dark {
  &--blackberry {
    --button-hover-color: 94, 23, 192;
  }

  &--mint {
    --button-hover-color: 2, 161, 133;
  }

  &--ice {
    --button-hover-color: 24, 77, 213;
  }

  &--tangerine {
    --button-hover-color: 233, 161, 22;
  }

  &--raspberry {
    --button-hover-color: 173, 3, 95;
  }

  &--blueberry {
    --button-hover-color: 18, 110, 194;
  }
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9px 16px;
  box-shadow: none;
  outline: none;
  border-radius: 8px;
  border: 1px solid rgb(var(--main-color));
  font-family: var(--font-roboto);
  font-weight: 500;
  font-size: 14px;
  text-decoration: none;
  line-height: 18px;
  color: #fff;
  cursor: pointer;
  transition: background-color .2s ease;

  & > .btn-field {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:hover {
    border-color: rgb(var(--button-hover-color));
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &.icon-only {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  &.primary {
    background-color: rgb(var(--main-color));

    &:hover {
      background-color: rgb(var(--button-hover-color));
      box-shadow: 0 0 15px rgba(var(--button-hover-color), .5);
      color: #fff;
    }

    &.disabled, &:disabled {
      border-color: #BABABA;
      background-color: #BABABA;
      color: #fff;
    }
  }

  &.outline {
    background-color: transparent;
    border: 1px solid rgb(var(--main-color));
    color: rgb(var(--main-color));

    &:hover {
      box-shadow: 0 0 0 1px inset rgb(var(--button-hover-color));
      border-color: rgba(var(--button-hover-color));
      color: rgba(var(--button-hover-color));
    }

    &.disabled, &:disabled {
      border-color: #BABABA;
      color: #BABABA;
    }
  }

  &:disabled, &.disabled {
    pointer-events: none;
  }

  .spinner {
    margin-left: 4px;
    border: 4px solid #BABABA;
    border-bottom: 4px solid rgba(255, 255, 255, 0.1);
    border-radius: 50%;
    width: 18px;
    height: 18px;
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

//Theme styles
.theme-3 {
  .btn {
    border-radius: 20px;
  }
}
