@use '../../../styles/mixins' as m;

.password-page {
  .password-page-description {
    margin-top: 35px;
    margin-bottom: 25px;
  }
}

@include m.point(lg-up) {
  .password-page {
    .mobile-buttons {
      display: none;
    }
  }
}

@include m.point(lg-low) {
  .password-page {
    .password-page-description {
      margin-top: 0;
      margin-bottom: 20px;
    }

    .page-nav-actions {
      display: none;
    }
  }
}
