$font-sizes: 11, 12, 13, 14, 15, 16, 18, 20, 24, 26, 30, 32;
$font-weights: 100, 200, 300, 400, 500, 600, 700, 800, 900;
$colors: (
        'purple-p': var(--purple-pressed),
        'purple': var(--purple),
        'yellow': var(--yellow),
        'green-dark': var(--green-dark)
);

@each $value in $font-sizes {
  ._fz-#{$value} {
    font-size: #{$value} + px;
  }
}

@each $value in $font-sizes {
  ._lh-#{$value} {
    line-height: #{$value} + px;
  }
}

@each $value in $font-weights {
  ._fw-#{$value} {
    font-weight: $value;
  }
}

@each $color, $value in $colors {
  ._text-#{$color} {
    color: $value;
  }
}

._ff-poppins {
  font-family: var(--font-poppins);
}

._ff-roboto {
  font-family: var(--font-roboto);
}

._text-up {
  text-transform: uppercase;
}

._text-c {
  text-align: center;
}

._text-r {
  text-align: right;
}

._text-l {
  text-align: left;
}

._text-italic {
  font-style: italic;
}

._text-y-c {
  vertical-align: middle;
}

._text-dec-u {
  text-decoration: underline;
}

.text-dec-n {
  text-decoration: none;
}

._text-color {
  color: rgb(var(--text-color));
}

._text-main {
  color: rgb(var(--main-color));
}

._text-accent {
  color: rgb(var(--accent-color));
}

._text-blue {
  color: #051367;
}

._text-grey {
  color: #a5a5a5;
}

._text-warning {
  color: var(--warning-color);
}

._grey-title {
  font-family: var(--font-roboto);
  font-size: 12px;
  font-weight: 500;
  color: #a5a5a5;
}

._form-title {
  margin-bottom: 25px;
  font-family: var(--font-roboto);
  font-size: 16px;
  font-weight: 600;
}

._word-break {
  word-break: break-word;
}

._pre {
  white-space: pre;
}

.text-link {
  font-size: 14px;
  color: rgb(var(--text-color));
}

//Theme styles
.light {
  ._form-title {
    color: rgb(var(--accent-color));
  }
}

.dark {
  ._form-title {
    color: rgb(var(--secondary-color));
  }
}