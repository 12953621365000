.simple-layout {
  --text-color: 38, 38, 38;
  position: relative;
  color: rgb(var(--text-color));

  &::after {
    content: "";
    opacity: .3;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
  }

  .page-form-wrap {
    z-index: 1;
    width: 100%;
    max-width: 570px;
    padding: 70px;
    background-color: #fff;
    border-radius: 8px;

    @media (max-width: 440px) {
      padding: 40px;
    }
  }

  .form-header-title,
  .input-field-label,
  .lang-switcher .fi-rr-caret-down{
    color: rgb(var(--accent-color));
  }

  .input-field {
    .input,
    .textarea,
    .select-input__control {
      background-color: #fff;
    }
  }

  .select-input .select-input__option {
    background-color: #fff;

    &--is-selected {
      background-color: rgba(var(--main-color), .2) !important;
    }

    &--is-focused {
      background-color: rgba(var(--main-color), .1);
    }
  }

  .lang-switcher {
    .dropdown {
      box-shadow: 0 0 20px 0 rgba(var(--main-color), .15);
      background-color: #fff;
    }

    .lang-switcher-option {
      color: rgb(38, 38, 38);
    }
  }
}