.modal {
  z-index: 1002;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.modal-overlay {
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(#000, .3);
}
//Theme styles
.modal-overlay.light {
  background-color: rgba(#000, .3);
}

.modal-overlay.dark {
  background-color: rgba(#fff, .2);
}