.profile-avatar {
  overflow: hidden;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;

  .fi-rr-circle-user::before {
    font-size: 40px;
  }
}

.header-profile-dropdown {
  transform: translate(-125px, 50px);
  width: 165px;

  .dropdown-footer {
    padding: 16px;
  }

  .dropdown-footer-divider {
    margin-bottom: 26px;
    height: 1px;
  }
}

//Theme styles
.light {
  .header-profile-dropdown {
    .dropdown-footer-divider {
      background-color: rgba(var(--main-color), .1);
    }
  }
}

.dark {
  .header-profile-dropdown {
    .dropdown-footer-divider {
      background-color: rgba(#fff, .1);
    }
  }
}
