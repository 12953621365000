@use '../../../styles/mixins' as m;

.burger {
  width: 30px;
  height: 20px;
  margin-right: auto;
  cursor: pointer;
  transition: transform .1s linear;

  @include m.point(lg-up) {
    display: none;
  }

  span {
    height: 2px;
    background-color: rgb(55, 58, 71);
    transition: transform .1s ease-out;
  }

  &.active {
    transform: translate(-5px, -5px);
    span {
      height: 3px;

      &:first-child {
        transform: rotate(45deg) translate(12px, 6px);
      }

      &:nth-child(2) {
        display: none;
      }

      &:last-child {
        transform: rotate(-45deg) translate(6px, 0);
      }
    }
  }
}

//Theme styles
.dark {
  .burger span {
    background-color: #fff;
  }
}