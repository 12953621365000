.auth-header {
  pointer-events: none;
  z-index: 1;
  top: 0;
  left: 0;
  padding-top: 12px;

  .auth-header-logo {
    width: 66px;
    height: 37px;
    font-size: 0;

    img {
      max-height: 100%;
    }
  }
}