@use '../../../../styles/mixins' as m;

.modal-template {
  z-index: 2;
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  padding: 22px;
  background-color: rgb(var(--section-color));
  border-radius: 8px;

  @media (max-width: 800px) {
    width: 98%;
  }

  .modal-template-header {
    margin-bottom: 24px;
  }

  .modal-template-content {
    overflow: auto;
    display: flex;
    flex-direction: column;
  }

  .modal-template-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: var(--font-size-30);
    font-weight: 600;

    span {
      padding-right: 15px;
      line-height: 1.3;
    }

    @include m.point(md-low) {
      font-size: var(--font-size-18);
    }
  }

  .modal-template-content-subtitle {
    margin-bottom: 30px;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    line-height: 20px;
  }

  .modal-template-close {
    position: absolute;
    top: 19px;
    right: 24px;
    cursor: pointer;
  }

  .input-field .date-range {
    position: relative;
    top: 7px;
    left: 0;
    display: inline-block;
  }

  .input-field .fi-rr-calendar {
    top: 10px;
  }

  .btn.primary {
    box-shadow: none !important;
  }

  &.sm {
    width: 470px;

    @include m.point(sm-low) {
      width: 425px;
    }

    @media (max-width: 360px) {
      width: 320px;
    }
  
    @media (min-width: 361px) and (max-width: 400px) {
      width: 360px;
    }
  
    @media (min-width: 401px) and (max-width: 440px) {
      width: 400px;
    }
  }

  &.md {
    width: 750px;

    @include m.point(md-low) {
      width: 425px;
    }

    @media (max-width: 360px) {
      width: 320px;
    }
  
    @media (min-width: 361px) and (max-width: 400px) {
      width: 360px;
    }
  
    @media (min-width: 401px) and (max-width: 440px) {
      width: 400px;
    }
  }

  &.bigSizeMedia{
    @include m.point(md-sm) {
      width: 800px;
    }
    
    @include m.point(sm-low) {
      width: 425px;
    }
  }

  &.lg {
    width: 900px;
    @include m.point(lg-md) {
      width: 700px;
    }
    @include m.point(md-sm) {
      width: 567px;
    }
    @include m.point(sm-low) {
      width: 425px;
    }
  }
}

//Theme styles
.light {
  .modal-template {
    box-shadow: 0 0 20px 0 rgba(var(--main-color), .15);
  }

  .modal-template-title {
    color: rgb(var(--accent-color));
  }

  .modal-template-content-subtitle {
    color: #262626;
  }
}

.dark {
  .modal-template {
    box-shadow: 0 0 20px 0 rgba(#000, .25);
  }

  .modal-template-title {
    color: rgb(var(--secondary-color));
  }

  .modal-template-content-subtitle {
    color: #fff;
  }
}