@use 'grid-variables' as v;
@use 'mixins';

.container-fluid {
  min-width: 320px;
  padding: 0 v.$grid-gutter;
}

.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 v.$grid-gutter;

  @each $label, $width in v.$grid-breakpoints {
    @include mixins.point("#{$label}-up") {
      max-width: map-get(v.$container-max-widths, $label);
    }
  }

  @include mixins.point(sm-low) {
    width: 100%;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -#{v.$grid-gutter};

  &.gap-24 {
    margin: 0 -12px;

    .col {
      padding-left: 12px;
      padding-right: 12px;
    }
  }

  &.gap-16 {
    margin: 0 -8px;

    .col {
      padding-left: 8px;
      padding-right: 8px;
    }
  }
}

.col {
  flex: 0 0 auto;
  padding: 0 v.$grid-gutter;
  width: 100%;
}

@each $point, $point-width in v.$grid-breakpoints {
  $columns: v.$grid-columns;
  @include mixins.point($point + '-up') {
    @for $col from 1 through $columns {
      $width: #{calc(100 / $columns * $col)} + '%';
      $col-class: unquote('.c-' + $point + '-' + $col);
      $offset-class: unquote('.o-' + $point + '-' + $col);

      #{$col-class} {
        width: $width
      }

      #{$offset-class} {
        margin-left: $width
      }
    }
  }
}
