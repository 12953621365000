@use "../../styles/mixins" as m;

.private-layout {
  padding-top: 64px;
  padding-left: 56px;

  .error-page {
    padding: 0;
  }
  @include m.point(lg-up) {
    .error-page {
      height: calc(100vh - 124px);
      background-color: rgb(var(--background-color));
    }
  }
  @include m.point(lg-low) {
    padding-left: 0;
  }
}

.private-layout-overflow {
  overflow-y: auto;
  height: 100%;
}

.private-layout-content {
  padding: 30px 16px 30px 24px;
}

//Theme styles
.theme-1,
.theme-3 {
  .private-layout {
    background-color: rgb(var(--background-color));
  }
}

.theme-2 {
  .private-layout {
    background-color: rgb(var(--section-color));
  }
}