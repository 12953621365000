._block {
  display: block;
}

._flex {
  display: flex;
}

._flex-col {
  flex-direction: column;
}

._flex-w {
  flex-wrap: wrap;
}

._flex-xy-c {
  justify-content: center;
  align-items: center;
}

._flex-j-c {
  justify-content: center;
}

._flex-j-s {
  justify-content: flex-start;
}

._flex-j-b {
  justify-content: space-between;
}

._flex-j-e {
  justify-content: flex-end;
}

._flex-j-a {
  justify-content: space-around;
}

._flex-a-c {
  align-items: center;
}

._flex-a-s {
  align-items: start;
}

._flex-a-f {
  align-items: stretch;
}

._flex-a-e {
  align-items: end;
}

._flex-gr-1 {
  flex-grow: 1;
}

._flex-sh-0 {
  flex-shrink: 0;
}

._i-block {
  display: inline-block;
}

._overflow-h {
  overflow: hidden;
}

._blur {
  pointer-events: none;
  filter: blur(5px);
}