@use '../../styles/mixins' as m;

.table-container {
  overflow-x: auto;
  position: relative;
  padding: 10px;
  margin-left: -10px;
  margin-right: -10px;

  table {
    position: relative;
    width: 100%;
    table-layout: fixed;
    border-spacing: 0;
    border-collapse: collapse;
    border-radius: 8px;
    border: none;
    @include m.point(lg-low) {
      width: auto;
      min-width: 1000px;
    }

    input[type='checkbox'] {
      opacity: 0;
      z-index: 99;
      position: absolute;
      bottom: 0;
      width: 20px;
      height: 20px;
    }
  }

  tbody {
    &.empty {
      display: block;
      min-height: 100px;
      box-shadow: none !important;

      .empty-msg {
        position: absolute;
        top: 40px;
        left: 0;
        bottom: 0;
        right: 0;
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
      }

      td {
        font-family: 'Poppins', sans-serif;
        font-size: 26px;
        background: transparent !important;
      }
    }
  }

  th,
  td {
    margin: 0;
    font-size: 12px;
    text-align: left;
  }

  th {
    position: relative;
    padding: 14px;
    text-transform: uppercase;
  }

  td {
    padding: 10px 14px;
    vertical-align: top;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 1.5;
  }
  .deposit,
  .completed {
    color: #53c41a;
  }

  .withdraw {
    color: #f6222e;
  }

  .pending {
    color: #2f80ed;
  }

  .status-badge {
    width: 8px;
    height: 8px;
    margin-right: 10px;
    align-self: center;
    border-radius: 50%;

    &.active {
      background: #53c41a;
    }

    &.blocked {
      background: #f6222e;
    }
  }

  .preloader {
    position: absolute;
    left: 10px;
    right: 10px;
    bottom: 10px;
    top: 50px;
    background: #ffffff30;
  }
}

.table-pagination {
  @include m.point(sm-low) {
    flex-direction: column;

    .table-pagination-buttons {
      margin-bottom: 5px;
    }
  }

  button {
    margin-right: 10px;
    border: none;
    outline: none;
    background: transparent;
    color: rgb(var(--text-color));
    cursor: pointer;

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  input,
  select {
    height: 30px;
    padding: 0 5px;
    background-color: transparent;
    border: 1px solid #c2c2c2;
    border-radius: 8px;
    color: rgb(var(--text-color));

    option {
      color: rgb(var(--accent-color));
    }

    &:focus {
      outline: none;
    }
  }

  input {
    max-width: 60px;
  }
}

//Theme styles
.light {
  .table-container {
    tr {
      background-color: #fff;
    }
  }
}

.dark {
  .table-container {
    tr {
      background-color: rgb(var(--section-color));
    }
  }
}

.theme-1,
.theme-3 {
  .table-container {
    tr {
      &:last-child {
        border-bottom: none;
      }
    }
  }
}

.theme-1,
.theme-2 {
  .table-container {
    th {
      background-color: rgb(var(--accent-color));
      font-weight: 500;
      color: #fff;
    }
  }
}

.theme-1 {
  .table-container {
    th {
      &:first-child {
        border-radius: 8px 0 0 0;
      }

      &:last-child {
        border-radius: 0 8px 0 0;
      }
    }

    tr {
      border-bottom: 1px solid #ebebeb;

      &:last-child {
        td:first-child {
          border-radius: 0 0 0 8px;
        }

        td:last-child {
          border-radius: 0 0 8px 0;
        }
      }
    }
  }
}

.theme-1--light {
  .table-container {
    table {
      box-shadow: 0 0 10px 0 rgba(var(--main-color), .15);
    }
  }
  .empty-table {
    table {
      background-color: rgb(var(--section-color));
    }
  }
}

.theme-1--dark {
  .table-container {
    table {
      box-shadow: 0 0 10px 0 rgba(#000, .25);
    }
  }
  .empty-table {
    table {
      background-color: rgb(var(--section-color));
    }
  }
}

.theme-1--dark,
.theme-3--dark {
  .table-container {
    tr {
      border-bottom: 1px solid rgba(#ebebeb, .3);
    }
  }
}

.theme-2 {
  .table-container {
    table {
      background: rgba(var(--main-color), .1);
    }

    thead {
      border-bottom: 3px solid transparent;
    }

    tbody {
      box-shadow: 0 0 10px 0 rgba(var(--main-color), .25);
      border-radius: 0 0 8px 8px;
    }

    tr {
      position: relative;
      border-bottom: 4px solid transparent;
      background-color: transparent;

      &:last-child {
        border-bottom: none;
      }
    }
  }

  td {
    position: relative;

    &:first-child {
      border-radius: 8px 0 0 8px;
    }

    &:last-child {
      border-radius: 0 8px 8px 0;
    }
  }
}

.theme-2--light {
  .table-container {
    tbody td {
      background: #fff;
    }
  }
}

.theme-2--dark {
  .table-container {
    tbody td {
      background: rgb(var(--background-color))
    }
  }
}

.theme-3 {
  .table-container {
    thead tr {
      background-color: transparent;
    }
  }
}

.theme-3--light {
  .table-container {
    th {
      color: rgb(var(--main-color));
    }

    tbody tr {
      background-color: #fff;
      border-bottom: 1px solid #ebebeb;

      &:nth-child(even) {
        background-color: #FDFDFD;
      }
    }
  }
}

.theme-3--dark {
  .table-container {
    tbody tr {
      &:nth-child(even) {
        background-color: #363652;
      }
    }
  }
}