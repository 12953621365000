@use '../../../styles/mixins' as m;

.personal-page {
  .personal-page-description {
    margin-top: 25px;
    margin-bottom: 25px;
  }
}

@include m.point(lg-up) {
  .personal-page {
    height: calc(100vh - 124px);

    .mobile-buttons {
      display: none;
    }
  }
  .personal-page-form {
    overflow-y: auto;
    overflow-x: hidden;
  }
}

@include m.point(lg-low) {
  .personal-page {
    .personal-page-description {
      margin-top: 0;
      margin-bottom: 20px;
    }
    .page-nav-actions {
      display: none;
    }
  }
}
