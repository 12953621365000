.meta-trader-page {
  .trader-img {
    background-color: #fff;
    font-size: 0;
  }

  .ios-link {
    display: inline-block;
    font-size: 0;
    background: #fff;
    border-radius: 4px;
  }

  .text-card {
    padding: 30px;
    border-radius: 8px;
  }

  ._form-title {
    margin-bottom: 15px;
  }
}

.meta-trader-content {
  min-height: calc(100vh - 190px);
}

.light {
  .text-card {
    background-color: #fff;

    .fi {
      color: rgb(var(--accent-color));
    }
  }
}

.dark {
  .text-card {
    background-color: rgb(var(--section-color));
  }
}

.theme-2--dark {
  .text-card {
    background-color: #232332;
  }
}