.chat-processed {
  position: absolute;
  bottom: calc(100% - 2px);
  right: 0;
  width: 100%;
  padding: 10px 60px 15px 10px;
  border-radius: 8px 8px 0;
  font-size: 14px;
  line-height: 1.3;

  &.isReplying {
    .chat-processed-text {
      padding-left: 40px;
    }
  }

  .chat-processed-title {
    margin-bottom: 10px;
    color: rgb(var(--main-color));
    & > span {
      padding-left: 3px;
      font-weight: 500;
      color: rgb(var(--text-color));
    }
  }

  .chat-processed-text {
    word-break: break-word;
  }

  .chat-processed-close {
    position: absolute;
    right: 8px;
    top: calc(50% - 16px);
    cursor: pointer;
  }
}
//Theme styles
.light {
  .chat-processed {
    box-shadow: 0 -6px 6px 2px rgba(var(--main-color), .15);
    background-color: rgb(var(--section-color));
  }
}

.dark {
  .chat-processed {
    background-color: rgb(var(--background-color));
    box-shadow: 0 -6px 6px 2px rgba(#000, .25);
  }
}
