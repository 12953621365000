.file-uploader {
  position: relative;
  border-radius: 8px;
  border: 1px dashed;

  .fi-rr-cloud-upload,
  .file-uploader-button {
    margin: 10px 0;
  }

  .file-uploader-title {
    margin-bottom: 10px;
  }

  .fi-rr-cross {
    color: red;
    cursor: pointer;
  }

  .submit-files {
    width: 180px;
  }

  .file {
    span {
      word-break: break-all;
      text-decoration: underline;
    }
  }

  .progress-bar {
    position: relative;
    width: 100%;
    max-width: 200px;
    overflow: hidden;
    border-radius: 8px;
    border: 1px solid rgb(var(--main-color));
  }

  .progress-bar-status {
    position: absolute;
    top: 2px;
    left: 10px;
    color: #fff;
  }

  .upload-progress {
    height: 20px;
    background: rgba(var(--main-color), .7);
  }

  label {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

//Theme styles
.light {
  .file-uploader {
    border-color: rgb(var(--accent-color));
    background-color: rgb(var(--secondary-color));
  }
}

.dark {
  .file-uploader {
    border-color: rgb(var(--main-color));
    background-color: rgb(var(--section-color));
  }
}

.theme-2--light {
  .file-uploader {
    background-color: #fff;
  }
}