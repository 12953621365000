.wallet {
  margin-bottom: 15px;
  input {
    z-index: -1;
    position: absolute;
    opacity: 0;
  }
}
.hide-horiz-scroll {
  overflow-x: hidden;
  overflow-y: auto;
}

.wallet-item {
  height: 100%;
  display: flex;
  border: 1px solid #c2c2c2;
  border-radius: 4px;
  padding: 10px 15px;
  align-items: center;
  cursor: pointer;

  &.selected {
    background: rgba(var(--main-color), 0.1);
  }

  &.error {
    border: 1px solid red;
  }
}

.wallet-icon i {
  display: block;
  width: 52px;
  height: 52px;
  margin-right: 10px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.wallet-desc {
  span {
    display: block;
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 18px;
  }

  span:nth-child(2) {
    word-break: break-all;
  }

  span:first-child {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
  }
}

.btc {
  background-image: url('/assets/img/crypto/btc.svg');
}

.ltc {
  background-image: url('/assets/img/crypto/ltc.svg');
}

.eth {
  background-image: url('/assets/img/crypto/eth.svg');
}

.dash {
  background-image: url('/assets/img/crypto/dash.svg');
}

.doge {
  background-image: url('/assets/img/crypto/doge.svg');
}

.bcy {
  background-image: url('/assets/img/crypto/bcy.svg');
}

.usdt {
  background-image: url('/assets/img/crypto/tether.svg');
}

.usdc {
  background-image: url('/assets/img/crypto/usdc.svg');
}

.trx {
  background-image: url('/assets/img/crypto/trx.svg');
}

.transaction {
  &__info {
    display: flex;
    @media (max-width: 450px) {
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  &__desc {
    padding: 8px;
    border-radius: 8px;
    background: rgba(242, 153, 74, 0.12);
    @media (max-width: 450px) {
      margin-left: 0;
      margin-top: 15px;
    }

    & > div {
      display: inline;
      word-break: break-word;
      padding: 0 3px;
    }

    .copy_id {
      display: block;
      margin: 10px 0;

      &:hover {
        cursor: pointer;
        opacity: 0.7;
      }
    }
  }

  &__empty {
    min-height: 100px;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
  }
}
