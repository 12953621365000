.chat-dialog-page {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 100%;
  width: 950px;
  height: 100%;
  margin: 0 auto;
  border-radius: 5px;

  .dialog-header {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 22px 15px;
    font-size: 24px;
    font-weight: 500;
  }

  .context-menu {
    width: 165px;
    height: 160px;
  }

  .context-menu--active {
    height: auto;
  }
}

//Theme styles
.light {
  .chat-dialog-page {
    background-color: rgb(var(--section-color));
  }
}

.theme-1--dark, .theme-3--dark {
  .chat-dialog-page {
    background-color: rgb(var(--section-color));
  }
}

.theme-2--dark {
  .chat-dialog-page {
    background-color: rgb(var(--background-color));
  }
}
