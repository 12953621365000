.toasts-wrapper {
  z-index: 1009;
  top: 0;
  right: 0;
  width: 300px;
}

.toast {
  padding: 20px;
  border-radius: 5px 0 0 5px;
  box-shadow: 0 0 12px #999;
  color: #fff;
  animation-name: fadeIn;
  animation-duration: .3s;

  & + & {
    margin-top: 5px;
  }
  &.success {
    background-color: #51a351;
  }

  &.warning {
    background-color: #f89406;
  }

  &.error {
    background-color: #bd362f;
  }
}

.toast-title {
  margin-bottom: 5px;
  font-weight: 600;
}

.toast-close {
  top: 0;
  right: 0;
  cursor: pointer;
}
