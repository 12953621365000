.filter-tag {
  white-space: nowrap;
  display: flex;
  margin: 3px 15px 3px 0;
  padding: 9px 8px 9px 12px;
  border-radius: 36px;
  border: 1px solid rgb(var(--main-color));
  font-size: 14px;
  color: rgb(var(--main-color));

  &__title {
    font-weight: 500;
    margin-right: 5px;
  }

  &__close {
    margin-left: 5px;
  }
}