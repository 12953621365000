.input-field {
  position: relative;
  margin-bottom: 15px;
  padding-top: 25px;
  font-family: var(--font-roboto);

  &.error {
    .input,
    .textarea,
    .select-input__control {
      border-color: red !important;
    }
  }

  .input,
  .textarea,
  .select-input__control {
    display: flex;
    width: 100%;
    background-color: #fff;
    outline: none;
    border: 1px solid #c2c2c2;
    border-radius: 8px;
    font-family: var(--font-roboto);
    font-size: var(--font-size-14);
    color: rgb(var(--text-color));

    &:focus {
      border-color: rgb(var(--main-color));
    }

    &:disabled {
      cursor: not-allowed;
    }
  }

  .input,
  .textarea {
    &:read-only {
      background-color: var(--light-grey-disabled);
      cursor: not-allowed;
    }
  }

  .input {
    height: 48px;
    padding-left: 12px;
  }

  .textarea {
    min-height: 45px;
    padding: 12px;
    resize: none;
  }

  .fi-rr-calendar {
    pointer-events: none;
    position: absolute;
    top: calc(50% - 12px);
    right: 10px;
    display: flex;
    font-size: 24px;
    color: rgb(var(--main-color));
  }

  .date-range {
    position: absolute;
    top: 55px;
    z-index: 10;
    left: 0;
    border: 1px solid rgb(var(--main-color));
    border-radius: 8px;
  }

  .rdrCalendarWrapper {
    border-radius: 8px;
  }

  .rdrDateDisplayWrapper {
    border-radius: 8px 8px 0 0;
  }

  .rdrDateDisplayItem input,
  .rdrMonthAndYearPickers select,
  .rdrDayNumber span {
    color: rgb(var(--text-color));
  }

  .rdrDayPassive {
    opacity: .6;
  }

  select {
    background: none;
    border-radius: 8px;
    border: 1px solid rgb(var(--main-color));
  }

  .rdrMonthPicker,
  .rdrYearPicker {
    position: relative;

    &::after {
      content: "";
      pointer-events: none;
      position: absolute;
      top: 10px;
      right: 9px;
      width: 8px;
      height: 8px;
      border-radius: 1px;
      border-bottom: 2px solid rgb(var(--main-color));
      border-right: 2px solid rgb(var(--main-color));
      transform: rotate(45deg);
    }
  }

  .rdrNextPrevButton {
    background: transparent;

    & > i {
      width: 8px;
      height: 8px;
      border-radius: 1px;
      border: none;
      border-bottom: 2px solid rgb(var(--main-color));
      border-right: 2px solid rgb(var(--main-color));
    }
  }

  .rdrNextButton i {
    transform: rotate(-45deg);
  }

  .rdrPprevButton i {
    transform: rotate(135deg);
  }

  .rdrDayToday .rdrDayNumber span:after {
    background-color: rgb(var(--main-color));
  }

  .rdrDayStartPreview, .rdrDayInPreview, .rdrDayEndPreview, .rdrDateDisplayItem {
    border-color: rgb(var(--main-color));
  }

  .rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge {
    background-color: rgb(var(--main-color));
  }

  .rdrDayNumber {
    font-weight: 400;
  }
}

.input-wrap {
  position: relative;
}

.input-field-label {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  font-size: var(--font-size-13);
  font-weight: 500;
  line-height: 20px;
  white-space: nowrap;
}

.required {
  .label-title::after {
    content: ' *';
  }
}

.error-message {
  overflow: hidden;
  width: 100%;
  margin-top: 4px;
  border-radius: 4px;
  font-size: var(--font-size-13);
  text-align: left;
  line-height: 14px;
  color: red;
}

//Theme styles
.light {
  .input-field {
    .input,
    .textarea,
    .select-input__control,
    .date-range,
    .rdrCalendarWrapper {
      background-color: #fff;

      &:disabled {
        background-color: #efefef;
        color: #989898;
      }
    }

    .input-field-label {
      color: rgb(var(--accent-color));
    }

    .rdrDateDisplayItem {
      background-color: rgb(var(--background-color));
    }
  }
}

.dark {
  .input-field {
    .input,
    .textarea,
    .select-input__control,
    .date-range,
    .rdrCalendarWrapper {
      background-color: rgb(var(--section-color));

      &:disabled {
        background-color: #9b9ba2;
      }
    }

    .input-field-label {
      color: rgb(var(--secondary-color));
    }

    .rdrDateDisplayWrapper,
    .rdrDateDisplayItem {
      background-color: rgb(var(--background-color));
    }
  }
}
