.loader {
  position: absolute;
  top: calc(50% - 25px);
  left: calc(50% - 25px);
  z-index: 10;
  width: 50px;
  height: 50px;
  border: 7px solid rgba(var(--accent-color), .4);
  border-radius: 50%;
  border-top-color: rgb(var(--main-color));
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;

  &__neutral {
    border-top-color: rgb(var(--text-color));
  }
}

@keyframes spin {
  to { transform: rotate(360deg); }
}
@-webkit-keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}