.context-menu {
  z-index: -1;
  position: fixed;
  pointer-events: none;
  opacity: 0;
  border-radius: 8px;
  padding: 4px 0;

  &--active {
    z-index: 5;
    pointer-events: all;
    opacity: 1;
    transition: opacity .1s ease;
  }
}

//Theme styles
.light .context-menu {
  box-shadow: 0 0 20px 0 rgba(var(--main-color), .15);
  background-color: rgb(var(--section-color));
}

.dark .context-menu {
  background-color: rgb(var(--background-color));
  box-shadow: 0 0 20px 0 rgba(#000, .25);
}