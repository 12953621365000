:root {
  --font-size-11: 11px;
  --font-size-12: 12px;
  --font-size-13: 13px;
  --font-size-14: 14px;
  --font-size-15: 15px;
  --font-size-16: 16px;
  --font-size-18: 18px;
  --font-size-20: 20px;
  --font-size-24: 24px;
  --font-size-26: 26px;
  --font-size-30: 30px;
  --font-poppins: 'Poppins', sans-serif;
  --font-roboto: 'Roboto', sans-serif;

  --chat-avatar-size-sm: 24px;
  --chat-avatar-size-md: 40px;
  --chat-status-color: #06D6A0;
  --chat-client-color: #138d65;

  --light-grey-disabled: 54, 49, 49;
  --warning-color: #FFB444;

  line-height: 1;
  font-family: var(--font-poppins);
  font-weight: 400;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: 100%;
}

@media screen and (max-width: 1376px) {
  :root {
    --font-size-11: 11px;
    --font-size-12: 12px;
    --font-size-13: 12px;
    --font-size-14: 12px;
    --font-size-15: 13px;
    --font-size-16: 14px;
    --font-size-18: 16px;
    --font-size-20: 18px;
    --font-size-24: 20px;
    --font-size-26: 22px;
    --font-size-30: 26px;
  }
}
*, :after, :before {
  box-sizing: border-box;
}

a {
  font-weight: 500;
  color: rgb(var(--main-color));
  &:hover {
    text-decoration: none;
    color: rgb(var(--accent-color));
  }
}

img, svg {
  max-width: 100%;
}

label {
  cursor: pointer;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

body {
  min-width: 320px;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  font-family: var(--font-poppins);
  font-size: 100%;
  color: rgb(var(--text-color));
}

h1 {
  margin: 0 0 30px 0;
  font-size: 30px;
  font-family: var(--font-poppins);
  font-weight: 600;
}

.light {
  h1 {
    color: rgb(var(--accent-color));
  }
}

.dark {
  h1 {
    color: rgb(var(--secondary-color));
  }
}