.amount-input {
  .input {
    min-width: 160px;
    padding-left: 67px;
  }

  .amount-input-row {
    max-width: 100%;

    @media (max-width: 400px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      width: 100%;

      .amount-input-wrap {
        width: 100%;
      }

      .amount-input-buttons {
        margin-left: auto;
      }
    }
  }

  .input-wrap {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
  }

  .counters {
    position: absolute;
    top: 1px;
    right: 1px;
    height: calc(100% - 2px);
    border-radius: 0 8px 8px 0;
    color: rgb(var(--main-color));
  }

  .details {
    min-width: 100%;
    margin-top: 5px;
    font-size: 13px;
  }

  .amount-button {
    margin-left: 8px;
    padding: 8px;
    border-radius: 20px;
    background-color: transparent;
    border: 1px solid rgb(var(--main-color));
    color: rgb(var(--main-color));
    cursor: pointer;
    transition: background-color .1s ease;

    &:hover, &.active {
      background-color: rgb(var(--main-color));
      color: #fff;
    }

    &.disabled {
      pointer-events: none;
      border-color: #c2c2c2;
      color: #c2c2c2;
    }
  }

  .fi {
    display: flex;
    flex-direction: column;
    height: 50%;
    width: 20px;
    cursor: pointer;
  }

  .fi-rr-angle-small-up {
    top: 0;
    justify-content: end;
  }

  .fi-rr-angle-small-down {
    top: 10px;
  }

  :disabled + .counters {
    pointer-events: none;
  }
}

.amount-input-currency {
  position: absolute;
  top: 1px;
  left: 1px;
  height: 46px;
  width: 55px;
  border-radius: 8px 0 0 8px;
  color: #999999;
}

//Theme styles
.light {
  .counters {
    background-color: #fff;
  }

  input:disabled + .counters {
    background-color: #efefef;
  }

  .amount-input-currency {
    background-color: #F5F5F5;
  }
}

.dark {
  .counters {
    background-color: rgb(var(--section-color));
  }


  input:disabled + .counters {
    background-color: #9b9ba2;
  }

  .amount-input-currency {
    background-color: #414159;
    border-right: 1px solid #999;
  }
}