.wire-transfers-table {
  table {
    thead tr {
      th:nth-child(1) {
        width: 19% !important;
      }

      th:nth-child(2) {
        width: 15% !important;
      }

      th:nth-child(3) {
        width: 15% !important;
      }

      th:nth-child(4) {
        width: 10% !important;
      }

      th:nth-child(5) {
        width: 10% !important;
      }

      th:nth-child(6) {
        width: 10% !important;
      }
    }
  }

  .account-name {
    display: flex;
    flex-direction: row;

    span {
      word-break: break-all;
      max-width: 50%;
    }
  }

  .btn {
    width: fit-content;
  }

  .wire-status {
    &__item {
      text-transform: uppercase;
      &.process, &.processing {
        color: #2f80ed;
      }

      &.success {
        color: #53c41a;
      }

      &.declined {
        color: #fbad15;
      }

      &.error, &.failed {
        color: #f6222e;
      }
    }
    & span:nth-child(2) {
      color: #595959;
      font-size: 12px;
    }
  }
}
.date-cell-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  ._grey-title {
    margin-left: 3px;
  }
}