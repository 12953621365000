.chat-message-form {
  position: relative;
  display: flex;
  align-items: center;
  max-height: 200px;
  font-size: 0;

  & > label:focus-within {
    outline: none;
  }

  &.isRedirecting {
    .message-sent-button {
      pointer-events: none;
    }
    textarea {
      font-size: 0;
    }
  }

  .attach-file-button {
    height: 24px;
    width: 24px;
    margin: 0 8px;
    color: rgb(var(--main-color));
  }

  .message-sent-button {
    z-index: 2;
    position: absolute;
    right: 4px;
    bottom: 3px;
    padding: 8px;
    border-radius: 50%;
    color: #fff;
  }
}
