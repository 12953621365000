.dropdown-wrap {
  z-index: 1;
  position: relative;

  .dropdown-button {
    cursor: pointer;
  }

  &.top {
    .dropdown {
      bottom: calc(100% + 10px);
      right: 50%;
      transform: translateX(-50%);
    }
  }

  &.right {
    .dropdown {
      left: calc(100% + 10px);
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &.left {
    .dropdown {
      right: calc(100% + 10px);
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &.bottom {
    .dropdown {
      top: calc(100% + 10px);
      right: 50%;
      transform: translateX(-50%);
    }
  }

  &.top-left {
    .dropdown {
      bottom: calc(100% + 10px);
      right: 0;
    }
  }

  &.top-right {
    .dropdown {
      bottom: calc(100% + 10px);
      left: 0;
    }
  }

  &.bottom-left {
    .dropdown {
      top: calc(100% + 10px);
      right: 0;
    }
  }

  &.bottom-right {
    .dropdown {
      top: calc(100% + 10px);
      left: 0;
    }
  }
}

.dropdown-portal {
  z-index: 1002;
  position: absolute;
  top: 0;
  left: 0;

  &.captured {
    width: 100%;
    height: 100%;
  }

  .dropdown {
    position: fixed;
  }
}

.dropdown {
  z-index: 2;
  position: absolute;
  width: 165px;
  padding: 7px 0;
  background-color: rgb(var(--section-color));
  border-radius: 8px;
}

.dropdown-option {
  padding: 11px 16px;
  color: rgb(var(--text-color));
  cursor: pointer;

  &:hover, &.active {
    background-color: rgba(var(--main-color), .1);
  }
}

.dropdown-option-icon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
  color: rgb(var(--main-color));
}

//Theme styles
.light .dropdown,
.light .dropdown-portal {
  box-shadow: 0 0 20px 0 rgba(var(--main-color), .15);
  background-color: rgb(var(--section-color));
}

.dark .dropdown,
.dark .dropdown-portal {
  background-color: rgb(var(--background-color));
  box-shadow: 0 0 20px 0 rgba(#000, .25);
}
