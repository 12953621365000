.chat {
  position: relative;
  display: flex;
  flex-direction: column;


  .chat-content {
    height: calc(100vh - 150px);
    margin-bottom: -30px;
    width: 100%;
    padding: 0 15px;
  }

  .connection-status {
    z-index: 1;
    pointer-events: none;
    position: absolute;
    top: -29px;
    left: 0;
    width: 100%;
    padding: 1px 15px;
    background-color: red;
    font-size: 14px;
    text-align: center;
    color: #fff;
  }

  .chat-empty-title {
    font-size: 32px;
    font-weight: 600;
    line-height: 1.5;
    color: rgba(var(--main-color), .3);
  }

  h1 {
    margin-bottom: 20px;
  }
}