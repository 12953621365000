.lang-switcher {
  .dropdown {
    overflow: auto;
    width: 165px;
    max-height: 215px;
  }

  .dropdown-button {
    display: flex;

    img, svg, i {
      flex-shrink: 0;
    }
  }

  .lang-switcher-option {
    padding: 8px 15px;
    color: rgb(var(--text-color));
    cursor: pointer;

    &.active {
      background-color: rgba(var(--main-color), .2) !important;
    }

    &:hover {
      background-color: rgba(var(--main-color), .1);
    }
  }
}

.light {
  .lang-switcher {
    .fi-rr-caret-down {
      color: rgb(var(--accent-color));
    }
  }
}
