.date-cell {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  &-row {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    ._grey-title {
      margin-left: 3px;
    }
  }
}
.status,
.type,
.deposit,
.withdraw,
.transfer_in,
.transfer_out,
.credit_in,
.credit_out {
  display: block;
}
.transfer_in {
  color: rgb(var(--main-color));
}
.transfer_out {
  color: var(--warning-color);
}
.credit_in {
  color: #bb9696;
}
.credit-out {
  color: var(--blue);
}
.deposit,
.completed {
  color: #53c41a;
}
.withdraw,
.canceled {
  color: #f6222e;
}
.pending {
  color: #2f80ed;
}
.transaction__id {
  display: flex;
  flex-direction: row;
  .live,
  .archived {
    display: block;
    color: #ffffff;
    padding: 2px 8px;
    border-radius: 50px;
    font-size: 12px;
    font-weight: 500;
    line-height: 17px;
    margin-left: 10px;
  }
  .live {
    background: #53c41a;
  }
  .archived {
    background: #f6222e;
  }
}
.trading_account,
.transaction__id {
  // font-size: 13px;
}
.trading_account {
  position: relative;
  span {
    white-space: nowrap;
    position: relative;
    top: -8px;
    margin-left: 5px;
    text-transform: uppercase;
    padding: 2px 8px;
    border-radius: 50px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    background: #fbad15;
    color: #ffffff;
  }
  p {
    display: inline-block;
  }
}
.status:first-letter,
.type:first-letter,
.deposit:first-letter,
.withdraw:first-letter,
.transfer_in:first-letter,
.transfer_out:first-letter {
  text-transform: uppercase !important;
}
