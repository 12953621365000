@use 'grid-variables' as v;

@mixin point($name) {
  $point-prefix-index: str-index($name, "-");
  $start-range-point: str-slice($name, 1, $point-prefix-index - 1);
  $end-range-point: str-slice($name, $point-prefix-index + 1);
  $min-width: map-get(v.$grid-breakpoints, $start-range-point);
  $max-width: map-get(v.$grid-breakpoints, $end-range-point);

  @if $end-range-point == up {
    @media (min-width: $min-width) {
      @content;
    }
  } @else if $end-range-point == low {
    @media (max-width: $min-width - 1) {
      @content;
    }
  } @else {
    @media (min-width: $min-width) and (max-width: $max-width - 1) {
      @content;
    }
  }
}

@mixin transform-center() {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}