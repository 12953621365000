.chat-avatar {
  position: relative;
  flex-shrink: 0;
  border-radius: 50%;

  &.sm {
    width: var(--chat-avatar-size-sm);
    height: var(--chat-avatar-size-sm);

    .chat-user-status {
      width: 6px;
      height: 6px;
    }

    .chat-avatar-initials {
      font-size: 12px;
    }
  }

  &.md {
    width: var(--chat-avatar-size-md);
    height: var(--chat-avatar-size-md);
  }

  &.group {
    .chat-avatar-wrap {
      background-color: var(--purple-pressed);
    }
  }

  &.client {
    .chat-avatar-wrap {
      background-color: var(--chat-client-color);
    }
  }

  &.manager {
    .chat-avatar-wrap {
      background-color: rgb(var(--main-color));
    }
  }

  .chat-avatar-wrap {
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    border-radius: 50%;
    background-color: rgb(var(--main-color));
  }

  .chat-avatar-initials {
    font-size: 18px;
    font-weight: 500;
    color: #fff;
  }

  .chat-user-status {
    position: absolute;
    bottom: 2px;
    right: 2px;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}