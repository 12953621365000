.withdraw-modal {
  .hide {
    pointer-events: none;
    opacity: 0;
    position: absolute;
  }

  .controls-warning {
    background: rgba(242, 153, 74, .1);
    border-radius: 8px;
    color: rgb(var(--text-color));
  }
}