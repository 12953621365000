:root {
  --main-color: 114, 46, 209;
  --secondary-color: 249, 241, 255;
  --accent-color: 5, 19, 103;
  --text-color: 38, 38, 38;
  --background-color: 246, 246, 246;
  --section-color: 255, 255, 255;
  --button-text-color: 255, 255, 255;
}

.light {
  --section-color: 255, 255, 255;
  --background-color: 246, 246, 246;
  --text-color: 38, 38, 38;

  &--blackberry {
    --main-color: 114, 46, 209;
    --secondary-color: 249, 241, 255;
    --accent-color: 5, 19, 103;
  }

  &--mint {
    --main-color: 26, 206, 174;
    --secondary-color: 232, 255, 251;
    --accent-color: 34, 64, 59;
  }

  &--ice {
    --main-color: 44, 99, 238;
    --secondary-color: 229, 236, 255;
    --accent-color: 42, 61, 108;
  }

  &--tangerine {
    --main-color: 231, 156, 12;
    --secondary-color: 254, 244, 225;
    --accent-color: 79, 56, 11;
  }

  &--raspberry {
    --main-color: 202, 22, 119;
    --secondary-color: 255, 238, 247;
    --accent-color: 64, 10, 39;
  }

  &--blueberry {
    --main-color: 30, 78, 122;
    --secondary-color: 232, 239, 245;
    --accent-color: 1, 33, 62;
  }
}

.dark {
  --section-color: 44, 44, 63;
  --background-color: 35, 35, 50;
  --text-color: 254, 254, 254;

  &--blackberry {
    --main-color: 153, 87, 246;
    --secondary-color: 249, 241, 255;
    --accent-color: 15, 29, 110;
  }

  &--mint {
    --main-color: 12, 193, 161;
    --secondary-color: 232, 255, 251;
    --accent-color: 34, 64, 59;
  }

  &--ice {
    --main-color: 71, 123, 255;
    --secondary-color: 229, 236, 255;
    --accent-color: 42, 61, 108;
  }

  &--tangerine {
    --main-color: 254, 172, 14;
    --secondary-color: 254, 244, 225;
    --accent-color: 79, 56, 11;
  }

  &--raspberry {
    --main-color: 204, 0, 110;
    --secondary-color: 255, 238, 247;
    --accent-color: 64, 10, 39;
  }

  &--blueberry {
    --main-color: 79, 149, 213;
    --secondary-color: 232, 239, 245;
    --accent-color: 1, 33, 62;
  }
}

.theme-2 {
  &--light {
    --section-color: 247, 247, 247;
    --background-color: 255, 255, 255;
    --text-color: 38, 38, 38;

    &.blackberry {
      --secondary-color: 31, 50, 164;
    }

    &.mint {
      --secondary-color: 13, 150, 126;
    }

    &.ice {
      --secondary-color: 75, 119, 237;
    }

    &.tangerine {
      --secondary-color: 168, 115, 13;
    }

    &.raspberry {
      --secondary-color: 151, 29, 95;
    }

    &.blueberry {
      --secondary-color: 25, 101, 166;
    }
  }

  &--dark {
    --section-color: 44, 44, 63;
    --background-color: 35, 35, 50;
    --text-color: 255, 255, 255;

    &.blackberry {
      --secondary-color: 249, 241, 255;
    }

    &.mint {
      --secondary-color: 232, 255, 251;
    }

    &.ice {
      --secondary-color: 232, 239, 245;
    }

    &.tangerine {
      --secondary-color: 232, 239, 245;
    }

    &.raspberry {
      --secondary-color: 232, 239, 245;
    }

    &.blueberry {
      --secondary-color: 232, 239, 245;
    }
  }
}
