.theme-switcher {
  cursor: pointer;
}

.theme-switcher-decor {
  position: relative;
  width: 32px;
  height: 16px;
  margin: 0 5px;
  border-radius: 8px;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    width: 16px;
    height: 16px;
    border-radius: 50%;
  }
}
//Theme styles
.dark {
  .theme-switcher-decor {
    background-color: rgb(var(--main-color));

    &::after {
      left: 0;
      background-color: rgb(var(--text-color));
    }
  }
}

.light {
  .theme-switcher-decor {
    background-color: rgba(var(--main-color), .1);

    &::after {
      right: 0;
      background-color: rgb(var(--main-color));
    }
  }

  .fi-rr-moon,
  .fi-rr-sun {
    color: rgb(var(--accent-color));
  }
}
