@use './src/styles/mixins' as m;

.sign-in-page {
  background-color: #fff;

  .page-title {
    font-size: 64px;
    line-height: 1.4;
    color: rgb(var(--main-color));

    span {
      padding: 0 20%;
    }
  }

  .page-form {
    width: 440px;
    max-width: 100%;
  }

  .input {
    background-color: #fff;
  }

  .form-header-title,
  .input-field-label {
    color: #051367;
  }

  @include m.point(lg-low) {
    .page-title {
      display: none;
    }

    .page-form-wrap {
      width: 100%;
    }
  }
}