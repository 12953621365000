@use '../../styles/mixins' as m;

.error-page {
  max-width: 1210px;
  gap: 110px;
  padding: 30px;

  &__number {
    font-size: 128px;
  }
}
@include m.point(lg-up) {
  .error-page {
    height: 100vh;
  }
}
@include m.point(lg-low) {
  .error-page {
    flex-direction: column;
    height: auto;

    &__img {
      order: 1;
      width: 300px;
    }

    &__description {
      width: 100%;
      max-width: 550px;
    }

    &__number {
      margin-bottom: 25px;
    }
  }
}
