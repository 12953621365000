.checkbox__icon {
  .input-wrap {
    font-size: 12px;
    line-height: normal;
    display: flex;
    align-items: flex-start;
  }

  .label-wrap {
    align-items: center;
    display: flex;
    position: relative;
    justify-content: space-between;

    .input__label,
    .input__label-no-click {
      font-size: 12px;
      color: var(--primary-text-color);
      display: block;
      user-select: none;
      height: 100%;
      width: 100%;
      position: relative;

      &:first-letter {
        text-transform: uppercase;
      }
    }
    .input__label-no-click {
      padding: 0;
      max-width: 94%;
    }

    &.no-click {
      align-items: flex-start;
    }
  }

  .required {
    margin-left: 3px;
  }

  .errorName {
    position: absolute;
    bottom: -15px;
    left: 0;
    font-size: 12px;
    color: var(--red);
  }

  .checkbox-input {
    z-index: -1;
    position: absolute;
    opacity: 0;
  }

  .error {
    .required,
    .fi-rr-square {
      color: red
    }

    .input__label,
    .input__label-no-click {
      color: darkred;
    }
  }
}

