table {
  thead tr {
    th:nth-child(1) {
      width: 80px !important;
    }
    th:nth-child(2) {
      width: 140px !important;
    }
    th:nth-child(3) {
      width: 120px !important;
    }
    th:nth-child(4) {
      width: 120px !important;
    }
    th:nth-child(5) {
      width: 120px !important;
    }
    th:nth-child(6) {
      width: 100px !important;
    }
    th:nth-child(7) {
      width: 100px !important;
    }
    th:nth-child(8) {
      width: 100px !important;
    }
    th:nth-child(9) {
      width: 100px !important;
    }
    th:nth-child(10) {
      width: 100px !important;
    }
    th:nth-child(11) {
      width: 120px !important;
    }
    th:nth-child(12) {
      width: 120px !important;
    }
    th:nth-child(13) {
      width: 100px !important;
    }
    th:nth-child(14) {
      width: 100px !important;
    }
    th:nth-child(15) {
      width: 120px !important;
    }
    th:nth-child(16) {
      width: 120px !important;
    }
  }

  tbody tr td {
    .date-cell-col {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      ._grey-title {
        margin-left: 3px;
      }
    }
  }
}

.buy, .pos {
  color: #53c41a;
}

.sell, .neg {
  color: #f6222e;
}