.account-table {
  &__demo {
      color: blue;
      font-weight: bold
  }

  &__transaction__id {
    display: flex;
    flex-direction: row;
    span:nth-child(2) {
      word-break: break-all;
      max-width: 50%;
    }
    span {
      display: block;
    }
  }

  .table-buttons {
    display: flex;
    align-items: center;
    i {
      display: inline-block;
      width: 24px;
      height: 24px;
      font-size: large;
      color: rgb(var(--main-color));
      margin-right: 16px;
    }
  }
}
