@use '../../../styles/mixins' as m;

.header-currency-info {
  .currency-icon {
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;

    span {
      font-size: 32px;
      font-weight: 500;
      color: rgb(var(--main-color));
    }
  }

  .currency-option {
    margin-left: 20px;
    font-family: Roboto, sans-serif;
  }

  .currency-option-title {
    white-space: nowrap;
    margin-bottom: 5px;
    font-family: Roboto, sans-serif;
    font-size: 12px;
    text-transform: uppercase;

    &.short {
      display: none;
    }
  }

  .currency-option-value {
    font-size: 14px;
    font-weight: 600;
    color: rgb(var(--main-color));
  }
}

@include m.point(md-up) {
  .header-currency-info {
    .currency:not(:first-child) {
      margin-left: 25px;
      padding-left: 25px;
      border-left: 1px solid;
    }
  }
}

@include m.point(lg-low) {
  .header-currency-info {
    .currency-option-title {
      display: none;

      &.short {
        display: block;
        margin-top: -15px;
      }
    }
  }
}

@include m.point(md-low) {
  .header-currency-info {
    flex-direction: column;
    flex-grow: 1;
    max-width: 200px;
    margin-bottom: -12px;
    margin-left: 5px;

    .currency {
      padding: 1px 0;

      &:last-child {
        .currency-option-title {
          display: none;
        }
      }
    }

    .currency-option {
      flex-shrink: 0;
      width: 50%;
      margin-left: 5px;
    }

    .currency-option-value {
      font-size: 12px;
    }

    .currency-icon {
      width: 20px;
      height: 20px;

      span {
        font-size: 18px;
      }
    }
  }
}

//Theme styles
.dark {
  .header-currency-info .currency {
    border-left-color: rgb(var(--background-color));
  }

  .currency-icon {
    background-color: rgb(var(--background-color));
  }

  .currency-option-title {
    color: rgb(var(--main-color));
  }

  .currency-option-value {
    color: rgb(var(--text-color));
  }
}

.light {
  .currency-option-title {
    color: rgb(var(--accent-color));
  }
}

.theme-1--light,
.theme-3--light {
  .header-currency-info .currency {
    border-left-color: rgb(var(--secondary-color));
  }

  .currency-icon {
    background-color: rgb(var(--secondary-color));
  }
}

.theme-2--light {
  .header-currency-info .currency {
    border-left-color: rgb(var(--background-color));
  }

  .currency-icon {
    background-color: rgb(var(--background-color));
  }
}

